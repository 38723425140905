<template>
  <b-modal
    :id="modalId"
    title="Note"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @show="clearForm"
    @hidden="clearForm"
    :no-close-on-backdrop="true"
  >
    <b-form @submit="handleSubmit">
      <b-form-group
        label="Employee"
        label-for="employee"
        class="required"
      >
        <b-form-select
          id="employee"
          v-model="form.employee"
          :options="employeesOptions"
          required
        />
      </b-form-group>
      <b-form-group
        label="Text"
        label-for="text"
        class="required"
      >
        <b-form-textarea
          id="text"
          v-model="form.text"
          rows="5"
          max-rows="6"
          required
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Post
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'NoteModalForm',
  props: ['modalId', 'employeesOptions'],
  data() {
    return {
      form: {
        text: '',
        employee: null
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.form);
    },
    clearForm() {
      this.form.text = '';
      this.form.employee = null;
    }
  }
}
</script>

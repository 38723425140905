<template>
  <b-form @submit="handleSubmit">
    <b-input-group class="w-100">
      <b-form-input v-model="input" :placeholder="placeholder" />
      <b-input-group-append>
        <b-button
          type="submit"
          variant="primary"
          class="px-2 py-0 d-flex align-items-center justify-content-center"
        >
          <i class="bx bx-search font-size-20" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form>
</template>

<script>
export default {
  name: 'Search',
  props: {
    search: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },
  data() {
    return {
      input: ''
    }
  },
  watch: {
    search (val) {
      if (val.length === 0) {
        this.input = ''
      }
    }
  },
  mounted() {
    if (this.filterEmployees) {
      this.input = this.filterEmployees.search
    }
  },
  computed:{
    filterEmployees() {
      return this.$store.state.employees.filterEmployees;
    },
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.input);
    }
  }
}
</script>

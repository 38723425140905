<template>
  <Layout v-load="loading" class="page">
    <NoteModalForm
      :modalId="noteModalId"
      :employeesOptions="employeesOptions"
      @onSubmit="handleNoteFormSubmit"
    />
    <EmployeeModalForm
      v-if="settings"
      :modalId="employeeModalId"
      :departments="settings.departments"
      :categories="settings.categories"
      :locations="settings.locations"
      :positions="settings.positions"
      :companies="settings.companies"
      :legalStatuses="settings.legalStatuses"
      :paymentSchemes="settings.paymentSchemes"
      :paymentTypes="settings.paymentTypes"
      @onSubmit="handleEmployeeFormSubmit"
    />
    <div class="d-md-flex justify-content-between mb-4 mt-3 page-head">
      <h3 class="mb-3 mb-md-0">Employees</h3>
      <div class="d-sm-flex page-head__employees">
        <b-button
            class="mr-2"
            variant="info"
            @click="downloadCsv"
        >
          <i class="fas fa-download"></i>
          All Contracts CSV
        </b-button>
        <b-button
            class="mr-2"
            variant="info"
            @click="downloadToExcel"
        >
          <i class="fas fa-download"></i>
          Download to CSV
        </b-button>
        <b-button
          class="mr-2"
          variant="primary"
          @click="() => this.$bvModal.show(this.employeeModalId)"
        >
          Add Employee
        </b-button>
        <b-button
          class="mr-2"
          variant="primary"
          @click="() => this.$bvModal.show(this.noteModalId)"
        >
          Note
        </b-button>
        <div class="mt-2 mt-sm-0 search-group">
          <Search
              @onSubmit="handleSearch"
              :search="filterEmployees.search || ''"
          />
        </div>
        <button v-if="isNotClear" @click="handlerClear" class="btn btn-secondary ml-2">Clear</button>
      </div>
    </div>
    <b-form class="mb-3">
      <div class="row">
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="company"
              v-if="settings"
              v-model="filters.company_id"
              :options="settings.companies"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('company', filters.company_id)"
          >
            <template #first>
              <b-form-select-option :value="null">Choose Company</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="category"
              v-if="settings"
              v-model="filters.category_id"
              :options="settings.categories"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('category', filters.category_id)"
          >
            <template #first>
              <b-form-select-option :value="null">Choose Category</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="department"
              v-if="settings"
              v-model="filters.department_id"
              :options="settings.departments"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('department', filters.department_id)"
          >
            <template #first>
              <b-form-select-option :value="null">Choose Department</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="location"
              v-if="settings"
              v-model="filters.location_id"
              :options="settings.locations"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('location', filters.location_id)"
          >
            <template #first>
              <b-form-select-option :value="null">Choose Location</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="location"
              v-if="settings"
              v-model="filters.status"
              :options="status"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('status', filters.status)"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-sm col-12">
          <b-form-select
              id="location"
              v-if="settings"
              v-model="filters.payment_type_id"
              :options="settings.paymentTypes"
              value-field="id"
              text-field="title"
              @change="handleApplyFilters('payment_type_id', filters.payment_type_id)"
          >
            <template #first>
              <b-form-select-option :value="null">Choose Payment Type</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group class="col-md-2 col-12">
          <b-form-select
              id="location"
              v-if="settings"
              v-model="filters.has_document"
              :options="documentTypes"
              value-field="value"
              text-field="title"
              @change="handleApplyFilters('has_document', filters.has_document)"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-md-2 col-12">
          <b-form-select
              id="location"
              v-if="settings"
              v-model="filters.completely_filled"
              :options="filledTypes"
              value-field="value"
              text-field="title"
              @change="handleApplyFilters('completely_filled', filters.completely_filled)"
          >
          </b-form-select>
        </b-form-group>
      </div>
    </b-form>
    <div class="card">
      <div
        v-if="list.length > 0"
        class="table-responsive employees"
      >
        <b-table responsive="lg" stacked="lg" hover :items="list" :fields="table.fields">
          <template #head(name)="data">
            <div class="d-flex align-items-center" @click="handleSortChange('name')" style="cursor: pointer">
              {{ data.label }} <i :class="[getSortedClass('name'), 'bx font-size-16 ml-2']" />
            </div>
          </template>
          <template #head(department)="data">
            <div class="d-flex align-items-center" @click="handleSortChange('departments.title')" style="cursor: pointer">
              {{ data.label }} <i :class="[getSortedClass('departments.title'), 'bx font-size-16 ml-2']" />
            </div>
          </template>
          <template #head(location)="data">
            <div class="d-flex align-items-center" @click="handleSortChange('locations.title')" style="cursor: pointer">
              {{ data.label }} <i :class="[getSortedClass('locations.title'), 'bx font-size-16 ml-2']" />
            </div>
          </template>
          <template #head(status)="data">
            <div class="d-flex align-items-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(payment)="data">
            <div class="d-flex align-items-center" @click="handleSortChange('payment_schemes.title')" style="cursor: pointer">
              {{ data.label }} <i :class="[getSortedClass('payment_schemes.title'), 'bx font-size-16 ml-2']" />
            </div>
          </template>
          <template #head(email)="data">
            <div class="d-flex align-items-center" @click="handleSortChange('email')" style="cursor: pointer">
              {{ data.label }} <i :class="[getSortedClass('email'), 'bx font-size-16 ml-2']" />
            </div>
          </template>
          <template #head(paymentType)="data">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center" @click="handleSortChange('payment_types.title')" style="cursor: pointer">
                {{ data.label }} <i :class="[getSortedClass('payment_types.title'), 'bx font-size-16 ml-2']" />
              </div>
            </div>
          </template>
          <template #head(contract)="data">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center" @click="handleSortChange('contracts.end_date')" style="cursor: pointer">
                  {{ data.label }} <i :class="[getSortedClass('contracts.end_date'), 'bx font-size-16 ml-2']" />
                </div>
              </div>
            </div>
          </template>
          <template #cell(#)="data">
            {{ data.index + 1 }}
            <router-link class="link-item" :to="`/employees/${data.item.uid}`"></router-link>
          </template>
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <div class="employees__item__image mr-3">
                <img :src="data.item.photo || AvatarDefault" alt="" />
              </div>
              <div>
                <div class="font-weight-bold">{{ data.item.name }}</div>
                <div class="mt-1">
                  {{ data.item.positions && data.item.positions.length ? data.item.positions[0].title : '' }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(department)="data">
            {{ data.item.company ? data.item.company.title : '' }}
            <div :style="{ color: data.item.department ? data.item.department.color : 'inherit' }">
              {{ data.item.positions && data.item.positions.length ? data.item.positions[0].department ? data.item.positions[0].department.title : '' : '' }}
            </div>
          </template>
          <template #cell(location)="data">
            {{ data.item.location ? data.item.location.title : '' }}
            <div>
              {{ data.item.category ? data.item.category.title : '' }}
            </div>
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
          </template>
          <template #cell(payment)="data">
            {{ data.item.payment_scheme ? data.item.payment_scheme.title : ''}}
          </template>
          <template #cell(gender)="data">
            {{ data.item?.gender ?? 'Undefined' }}
          </template>
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>
          <template #cell(paymentType)="data">
            {{ data.item.payment_type ? data.item.payment_type.title : '---' }}
          </template>
          <template #cell(filledProfile)="data">
            {{ data.item?.profile?.completely_filled ? 'Yes' : 'No' }}
          </template>
          <template #cell(has_document)="data">
            {{ data.item?.has_document ? 'Yes' : 'No' }}
          </template>
          <template #cell(contract)="data">
            {{ data.item.contract && data.item.contract.end_date ? $moment(data.item.contract.end_date).format('DD.MM.YYYY') : '' }}
          </template>
        </b-table>

        <div class="col mb-2 mt-2 text-right">
          <span class="font-weight-bold">Total: </span>{{totalRows}}
        </div>
      </div>
      <div class="text-center p-3 font-size-16 text-muted" v-else><em>According to the filters there are no employees</em></div>



      <div class="pagination">
        <div class="pagination__per-page">
          <span>Per page:</span>
          <select id="perPage" class="form-control" v-model="pageLimit">
            <option
              v-for="limit in pageLimits"
              :key="limit"
              :value="limit"
            >
              {{ limit }}
            </option>
          </select>
        </div>
        <div class="pagination__page-number">
          <b-pagination
              v-if="totalRows > pageLimit"
              align="right"
              v-model="page"
              :per-page="pageLimit"
              :total-rows="totalRows"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import Search from '@/components/search.vue';
import Layout from '@/router/layouts/main.vue';
import NoteModalForm from './noteModalForm.vue';
import EmployeeModalForm from './employeeModalForm.vue';
import AvatarDefault from '@/assets/images/users/avatar-default.svg';


export default {
  name: 'EmployeesPage',
  components: {
    Layout,
    Search,
    NoteModalForm,
    EmployeeModalForm,
  },
  data() {
    return {
      AvatarDefault,
      page: 1,
      search: '',
      pageLimit: 20,
      pageLimits: [20, 40, 60, 80, 100],
      noteModalId: 'note-modal',
      employeeModalId: 'employee-modal',
      sort: {
        field: '',
        direction: ''
      },
      filters: {
        company_id: null,
        category_id: null,
        location_id: null,
        department_id: null,
        status: 'all',
        payment_type_id: null,
        has_document: null,
        completely_filled: null,
      },
      status: [
        { id: 'all', title: 'Choose Employee Status' },
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' },
      ],
      documentTypes: [
        { id: 1, title: 'Choose has documents', value: null},
        { id: 2, title: 'Yes', value: 'true' },
        { id: 3, title: 'No', value: 'false' },
      ],
      filledTypes: [
        { id: 1, title: 'Choose filled profile', value: null},
        { id: 2, title: 'Yes', value: 'true' },
        { id: 3, title: 'No', value: 'false' },
      ],
      table: {
        fields: [
          {
            key: '#',
            label: '#',
          },
          {
            key: 'name',
            label: 'Name and position',
          },
          {
            key: 'department',
            label: 'Department',
          },
          {
            key: 'location',
            label: 'Location',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'payment',
            label: 'Payment',
          },
          {
            key: 'gender',
            label: 'Gender',
            thStyle: { minWidth: '100px', width: '100px' }
          },
          {
            key: 'Email',
            label: 'Email',
          },
          {
            key: 'paymentType',
            label: 'Payment type',
          },
          {
            key: 'filledProfile',
            label: 'Filled profile',
          },
          {
            key: 'has_document',
            label: 'Documents',
          },
          {
            key: 'contract',
            label: 'Contract',
          },
        ]
      },
      firstPaginationLoad: false
    }
  },
  watch: {
    pageLimit(val) {
      if (val && !this.firstPaginationLoad)
      this.$store.dispatch('employees/getEmployees', this.queryParams);
      this.$store.commit('employees/SET_PAGINATION_SETTING', { page: this.page, limit: val })
      this.$router.push(`?page=${this.page}&limit=${val}`).catch(() => false)
    },
    page (val) {
      if (val && !this.firstPaginationLoad) {
        this.$store.dispatch('employees/getEmployees', this.queryParams);
        this.$store.commit('employees/SET_PAGINATION_SETTING', { page: val, limit: this.pageLimit })
        this.$router.push(`?page=${val}&limit=${this.pageLimit}`).catch(() => false)
      }
    }
  },
  mounted() {
    this.setPaginationConfig()
    this.fillFilterInputs()
    this.$store.dispatch('employees/getEmployees', this.queryParams);
  },
  computed: {
    list() {
      return this.$store.state.employees.list;
    },
    settings() {
      return this.$store.state.settings
    },
    filterEmployees() {
      return this.$store.state.employees.filterEmployees;
    },
    loading() {
      return this.$store.state.employees.loading;
    },
    totalRows() {
      return this.$store.state.employees.totalItems;
    },
    ...mapGetters('employees', ['employeesOptions','message']),
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit,
        search: this.search ? this.search : null,
        //department_id: this.department_id,
        ...this.getFilters(),
        ...this.getSortParams()
      }
    },
    isNotClear() {
      return this.search
          || this.filters.category_id
          || this.filters.department_id
          || this.filters.location_id
          || this.filters.payment_type_id
          || this.filters.status !== 'all'
          || this.filters.completely_filled
          || this.filters.has_document
    }
  },
  methods: {
    ...mapActions('employees',['exportEmployeesCsv', 'downloadContractCsv']),
    async downloadToExcel(){
      await this.exportEmployeesCsv(this.queryParams);
      await this.csvDownload(this.message);
    },
    async downloadCsv() {
      await this.downloadContractCsv(this.queryParams)
    },
    getFilters() {
      let index = 0;
      return Object.keys(this.filters).reduce((acc, key) => {
        if (this.filters?.[key]) {
          acc[`filter[${index}][column]`] = key;
          acc[`filter[${index}][value]`] = this.filters[key];
          index += 1;
        }
        return acc;
      }, {});
    },
    handleApplyFilters(key, value) {
      if (key) {
        this.routeChangeHandler(key, value)
      }
      this.page = 1;
      this.$store.dispatch('employees/getEmployees', this.queryParams);
    },
    getSortParams() {
      const params = {
        'sort[0][sort]': null,
        'sort[0][sortBy]': null
      }
      if (this.sort.field && this.sort.direction) {
        params['sort[0][sortBy]'] = this.sort.field;
        params['sort[0][sort]'] = this.sort.direction === 'asc' ? 1 : -1;
      }

      return params;
    },
    getCurrentDirection() {
      if (this.sort.direction === 'asc') {
        return 'desc';
      }
      if (this.sort.direction === 'desc') {
        return '';
      }
      if (this.sort.direction === '') {
        return 'asc';
      }
    },
    handleSortChange(field) {
      if (field === this.sort.field) {
        this.sort.direction = this.getCurrentDirection();
      } else {
        this.sort.field = field;
        this.sort.direction = 'asc';
      }
      this.$store.dispatch('employees/getEmployees', this.queryParams);
    },
    getSortedClass(field) {
      if (field === this.sort.field) {
        if (this.sort.direction === 'asc') {
          return 'bx-sort-down'
        }
        if (this.sort.direction === 'desc') {
          return 'bx-sort-up'
        }
        return 'bx-sort';
      }

      return 'bx-sort';
    },
    handleSearch(search) {
      this.routeChangeHandler('search', search)
      this.search = search;
      this.page = 1;
      this.$store.dispatch('employees/getEmployees', this.queryParams);
    },
    openEmployee(id) {
      this.$router.push(`/employees/${id}`);
    },
    handleNoteFormSubmit(data) {
      this.$store.dispatch('employees/createEmployeeNote', {
        id: data.employee,
        data: this.$convertObjectToFormData({ text: data.text })
      });
      this.$bvModal.hide(this.noteModalId);
    },
    handleEmployeeFormSubmit(data) {
      let form = {}
      form.name = data.name
      if (data.phone) {
        form.phone = data.phone
      }
      if (data.email) {
        form.email = data.email
      }
      form.gender = data.gender ?? null
      form.category_id = data.category
      form.department_id = data.department
      form.location_id = data.location
      form.positions_ids = data.positions.map(obj => obj.id) //data.positions.map(({ value }) => value)
      form.company_id = data.company
      form.legal_status_id = data.legalStatus
      form.payment_scheme_id = data.paymentScheme
      form.payment_type_id = data.paymentType
      form.sso_enabled = data.sso
      this.$store.dispatch('employees/createEmployee', this.$convertObjectToFormData(form))
        .then((response) => {
          if (response) {
            this.$bvModal.hide(this.employeeModalId);
          }
        });
    },
    routeChangeHandler(key, value) {
      this.$store.commit('employees/SET_FILTER_EMPLOYEES', {key, value})
    },
    onDeleted(id) {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('employees/deleteEmployee', {
            id
          })
        }
      })
    },
    async handlerClear() {
      this.search = ''
      this.$store.commit('employees/SET_NULL_FILTER_EMPLOYEE')
      this.filters = {
        category_id: null,
        department_id: null,
        location_id: null,
        payment_type_id: null,
        company_id: null,
        status: 'all',
        has_document: null,
        completely_filled: null,
      }
      await this.$store.dispatch('employees/getEmployees', this.queryParams);
    },
    setPaginationConfig () {
      this.firstPaginationLoad = true
      if (this.$store.getters['employees/getLimitPage']) {
        this.pageLimit = this.$store.getters['employees/getLimitPage']
      }
      const { page, limit } = this.$route.query;
      this.page = +page + 1 ? +page : 1;
      this.pageLimit = this.pageLimits.includes(+limit) ? +limit : this.pageLimits[0];
      setTimeout(() => {
        this.firstPaginationLoad = false
      }, 1000)
    },
    fillFilterInputs() {
      this.filters.category_id = this.filterEmployees?.category || null
      this.filters.department_id = this.filterEmployees?.department || null
      this.filters.location_id = this.filterEmployees?.location || null
      this.filters.payment_type_id = this.filterEmployees?.payment_type_id || null
      this.filters.status = this.filterEmployees?.status || 'all'
      this.filters.completely_filled = this.filterEmployees?.completely_filled || null
      this.filters.has_document = this.filterEmployees?.has_document || null
      this.search = this.filterEmployees?.search || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  thead {
    th {
      cursor: pointer;
    }
  }
  tr {
    position: relative;
  }
}
.link-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.employees__item-delete {
  position: relative;
  z-index: 2;
}
</style>

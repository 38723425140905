<template>
  <b-modal
    :id="modalId"
    size="lg"
    title="New Employee"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @show="clearForm"
    @hidden="clearForm"
  >
    <b-form
      class="employee-form"
      @submit="handleSubmit"
    >
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Name
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model="form.name" required>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Email</label>
          </div>
          <div class="col-md-9">
            <input type="email" class="form-control" v-model="form.email">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Phone</label>
          </div>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model="form.phone">
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="gender">Gender</label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="gender"
                v-model="form.gender"
                :options="genders"
                value-field="value"
                text-field="title"
            >
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Department
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="department"
                v-model="form.department"
                :options="departments"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose department</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Position
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <Multiselect
                v-model="form.positions"
                :options="getPositions"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="title"
                track-by="id"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Category
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="category"
                v-model="form.category"
                :options="categories"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Category</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Locations
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="locations"
                v-model="form.location"
                :options="locations"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Location</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Company
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="companies"
                v-model="form.company"
                :options="companies"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Company</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Legal status
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="legal-status"
                v-model="form.legalStatus"
                :options="legalStatuses"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Legal Status</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Payment scheme
              <span class="label-icon">*</span>

            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="payment-scheme"
                v-model="form.paymentScheme"
                :options="paymentSchemes"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Payment Scheme</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Payment type
              <span class="label-icon">*</span>
            </label>
          </div>
          <div class="col-md-9">
            <b-form-select
                id="payment-scheme"
                v-model="form.paymentType"
                :options="paymentTypes"
                value-field="id"
                text-field="title"
                required
            >
              <template #first>
                <b-form-select-option value="null" disabled>Choose Payment Type</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
<!--      <b-form-group-->
<!--        label-cols-md="2"-->
<!--      >-->
<!--        <b-form-checkbox-->
<!--          id="sso"-->
<!--          v-model="form.sso"-->
<!--        >-->
<!--          SSO enabled-->
<!--        </b-form-checkbox>-->
<!--      </b-form-group>-->
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Create
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';
import Multiselect from 'vue-multiselect';

export default {
  name: 'EmployeeModalForm',
  components: { Multiselect },
  props: [
    'modalId',
    'departments',
    'positions',
    'categories',
    'locations',
    'companies',
    'legalStatuses',
    'paymentSchemes',
    'paymentTypes'
  ],
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        gender: null,
        department: null,
        positions: [],
        category: null,
        location: null,
        company: null,
        legalStatus: null,
        paymentScheme: null,
        paymentType: null,
        sso: false
      },
      genders: [
        {id: 1, title: 'Male', value: 'male'},
        {id: 2, title: 'Female', value: 'female'},
        {id: 3, title: 'Undefined', value: null},
      ],
    }
  },
  computed:{
    getPositions(){
      //console.log(this.positions, this.form.department)
      let result = [];
      if(this.form.department) result = this.positions.filter(item=>item.department_id === this.form.department);
      return result;
    }
  },
  methods: {
    validateForm() {
      let isValid = true;

      const formEntries = Object.entries(this.form);
      for (let i = 0; i < formEntries.length; i += 1) {
        const [key, value] = formEntries[i];
        if (Array.isArray(value) && value.length === 0) {
          this.$showErrorToast(`${startCase(key)} is required`);
          isValid = false;
          break;
        }

        if (!isBoolean(value) && !value && !(key === 'email' || key === 'phone' || key === 'gender')) {
          this.$showErrorToast(`${startCase(key)} is required`);
          isValid = false;
          break;
        }

        if (this.form.category === 3 && !value && !isBoolean(value) && !(key === 'email' || key === 'phone' || key === 'gender')) {
          this.$showErrorToast(`${startCase(key)} is required`);
          isValid = false;
          break;
        }
      }

      return isValid;
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.validateForm()) {
        this.$emit('onSubmit', this.form);
      }
    },
    clearForm() {
      this.form.name = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.department = null;
      this.form.positions = [];
      this.form.category = null;
      this.form.location = null;
      this.form.company = null;
      this.form.legalStatus = null;
      this.form.gender = null
      this.form.paymentScheme = null;
      this.form.paymentType = null;
      // this.form.sso = false;
    }
  }
}
</script>

<style lang="scss">
  .employee-form {
    .custom-control-label {
      vertical-align: middle;
    }
  }

  @media (max-width: 575px) {
    .employee-form {
      .form-group {
        label {
          text-align: left !important;
        }
      }
    }
  }
</style>
